<template>
  <section id="firstBanner" class="bg-grey">
    <b-container class="topLogoContainer" fluid>
      <div class="videoBg" :style="{ minHeight: firstBannerTotalH + 'px' }">
        <client-only>
          <div
            class="bannerVideo embed-responsive embed-responsive-16by9"
            :style="{ minHeight: firstBannerTotalH + 'px' }"
          >
            <video
              autoplay
              muted
              loop
              class="embed-responsive-item"
              allowfullscreen
              alt="Ariel drone shot of city scape."
              playsinline
            >
              <source src="/img/hero/Hero.mp4" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </client-only>
      </div>
      <div
        class="upperLayout d-flex align-items-center justify-content-center"
        :style="{ minHeight: firstBannerH + 'px' }"
      >
        <div class="southbayLogo">
          <img
            src="/img/hero/SOUTHBAY_LOGO.svg"
            class="img-fluid"
            alt="Southbay Logo"
          />
        </div>
        <div class="text-center text-white pt-4">
          <p class="medium-text freight-big-pro-book">
            SET YOUR SIGHTS ON EVERYTHING YOU NEED
          </p>
          <div class="pt-lg-screens">
            <p
              class="desktop small-text freight-big-pro-book-italic lg-screen-text"
            >
              Wasaga Beach’s Nature-Inspired Master-Planned Community
            </p>
            <p
              class="mobile small-text freight-big-pro-book-italic lg-screen-text"
            >
              Wasaga Beach’s Nature-Inspired <br />
              Master-Planned Community <br />
            </p>
            <br />
            <p class="small-text freight-big-pro-book-italic lg-screen-text">
              Towns <span class="freight-big-pro-book xs-text">FROM THE MID</span>  $700’s,   
            </p>
            <p class="small-text freight-big-pro-book-italic lg-screen-text"> 
              40' Detached <span class="freight-big-pro-book xs-text">FROM THE LOW</span> $900’s, 
            </p>
            <p class="small-text freight-big-pro-book-italic lg-screen-text"> 
              43' Detached <span class="freight-big-pro-book xs-text">FROM THE MID</span> $900’s, 
            </p>
            <p class="small-text freight-big-pro-book-italic lg-screen-text">
              50'
              Detached <span class="freight-big-pro-book xs-text">FROM THE LOW</span> $1M’s
            </p>
          </div>
        </div>
        <div>
          <p
              class="desktop small-text freight-big-pro-book-italic lg-screen-text white"
              v-scroll-to="{ el: '#presentation', offset: -100 }"
            >
              <br />
              OUR PRESENTATION CENTRE IS NOW OPEN
          </p>
          <p
            class="mobile small-text freight-big-pro-book-italic lg-screen-text white"
             v-scroll-to="{ el: '#presentation', offset: 0 }"
          >
              <br />
              OUR PRESENTATION CENTRE <br />
              IS NOW OPEN <br />
          </p>
        </div>
        <div class="footer-logo">
          <div class="row h-100">
            <div class="col pt-3">
              <img
                src="/img/hero/FERNBROOK.svg"
                class="img-fluid footerImg"
                alt="Fernbrook Logo"
              />
            </div>
            <div class="line"></div>
            <div class="col pt-3">
              <img
                src="/img/hero/ZANCOR.svg"
                class="img-fluid footerImg"
                alt="Zancor Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
import jq from "jquery";
export default {
  data() {
    return {
      firstBannerH: null,
      firstBannerTotalH: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getMidHeight();
      jq(window).on("resize", () => {
        this.getMidHeight();
        setTimeout(() => {
          this.getMidDomHeight();
        }, 500);
      });
      setTimeout(() => {
        this.getMidDomHeight();
      }, 100);
    });
  },
  methods: {
    getMidHeight() {
      this.firstBannerH = jq(window).height();
    },
    getMidDomHeight() {
      this.firstBannerTotalH = jq("#firstBanner").height();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/styles/variables";
#firstBanner {
  background-color: $m-cream;
  overflow: hidden;
  .topLogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .videoBg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      video {
        object-fit: cover;
        background-color: $m-cream;
      }
    }
    .upperLayout {
      width: 100%;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .xs-text {
        font-size: 12px;
      }

      .desktop {
        display: block;
        @media screen and (max-width: 750px) {
          display: none;
        }
      }
      .mobile {
        display: none;
        @media screen and (max-width: 750px) {
          display: block;
        }
      }
      .southbayLogo {
        width: 400px;
        display: block;
        padding-bottom: 60px;
        @media screen and (min-width: 1500px) {
          width: 700px;
          padding-bottom: 80px;
        }
        @media screen and (max-width: 450px) {
          width: 300px;
        }
      }
      .footer-logo {
        height: 15vh;
        position: absolute;
        bottom: 0;
        .footerImg {
          width: 100px;
          @media screen and (min-width: 2500px) {
            width: 120px;
          }
        }
        .line {
          border-right: 1px solid white;
        }
        @media screen and (min-width: 2500px) {
          height: 10vh;
        }
      }
      .lg-screen-text {
        @media screen and (min-width: 1500px) {
          font-size: 1.66667vw;
          line-height: 0.4125;
        }
        @media screen and (min-width: 2500px) {
          font-size: 2rem;
          line-height: 1rem;
          padding-bottom: 20px;
        }
      }
      .pt-lg-screens {
        @media screen and (min-width: 1500px) {
          padding-top: 20px;
        }
      }
    }
  }
}
</style>
